'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.ScrollLinks = function ScrollLinks() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    [].slice.call($('[data-scroll]')).forEach(function (item) {
      item.addEventListener('click', function (e) {
        e.preventDefault();
        var $target = document.querySelector(item.getAttribute('href'));

        if ($target) {
          var $menu = document.querySelector('header');
          window.scroll({
            top: parseFloat($target.offsetTop - $menu.offsetHeight),
            left: 0,
            behavior: 'smooth'
          });
        }
      });
    });

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();