'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.Slider = function Slider() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $slider = document.querySelector('.slider .slider-element');

    if ($slider) {
      var flkty = new Flickity($slider, {
        cellAlign: 'center',
        contain: true,
        wrapAround: true,
        imagesLoaded: true,
        prevNextButtons: false,
        pageDots: false
      });

      var $slides = [].slice.call($slider.querySelectorAll('.slider-item'));
      var $dots = [].slice.call(document.querySelectorAll('.slider-dots .slider-dot'));

      flkty.on('select', function (index) {
        var realIndex = $slides[index].dataset.index;

        $dots.forEach(function ($dot) {
          var dotIndex = $dot.dataset.index;

          if (dotIndex === realIndex) {
            $dot.classList.add('is-active');
          } else {
            $dot.classList.remove('is-active');
          }
        });
      });

      $dots.forEach(function ($dot) {
        $dot.addEventListener('click', function (e) {
          e.preventDefault();

          var dotIndex = $dot.dataset.index;
          flkty.select(dotIndex);
        });
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();